import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

import { kebabCase } from 'lodash';
import {MainList,CatList} from '../components/utils.js'

const TechSet = (props) => {
	return (
		<ul className={"tagList"}>
			{props.allTechnology.map(technology => (
			<Link key={technology.fieldValue} to={`/technology/${kebabCase(technology.fieldValue)}/`}>
				<li>
					{technology.fieldValue} ({technology.totalCount})
				</li>
			</Link>
			))}
		</ul>
	)
}

export const formattedImage = graphql`
  fragment formattedImage on File {
    childImageSharp {
      fluid(maxWidth: 1080, maxHeight: 720) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const Technologies = ({ data }) => {
	const allTechnology = data.projects.group;
	const totalCount = data.projects.totalCount;
	const totalTalk = data.talks.totalCount;
	return (
	<Layout>
		<div className={"container"}>
			<MainList project={true} total={totalCount} totalTalk={totalTalk}/>
			<CatList technology={true}/>
			<TechSet allTechnology={allTechnology} totalCount={totalCount}/>
		</div>
	</Layout>
  )
}
export const query = graphql`
  query {
    projects: allMarkdownRemark(
    	sort: { fields: [frontmatter___date], order: DESC }
    	filter: {frontmatter: {template: {eq: "project"}}}
    ) {
      totalCount
      group(field: frontmatter___technology) {
        fieldValue
        totalCount
      }
    }
    talks:allMarkdownRemark(
		sort: { fields: [frontmatter___date], order: DESC }
		filter: {frontmatter: {template: {eq: "talk"}}}
	) {
		totalCount
	}
  }
`

export default Technologies;